import { gql } from 'graphql-tag';
import { PRODUCT_FIELDS, PRODUCT_PRICE_GROUP_FIELDS, PRODUCT_CUSTOMER_PRICE_FIELDS } from './Fragments';

export const CREATE_PRODUCT = gql`
    mutation CreateProduct($input: ProductInput!) {
        create_product(product: $input)
    }
`;

export const UPDATE_PRODUCT_PRICE = gql`
    mutation UpdateProductPrice($type: String, $value: String, $id: String, $change_type: String, $modal: String) {
        update_product_price(type: $type, value: $value, id: $id, change: $change_type, modal: $modal)
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($input: ProductInput!) {
        update_product(product: $input) {
            ...ProductFields
        }
    }
    ${PRODUCT_FIELDS}
`;

export const DELETE_PRODUCT = gql`
    mutation DeleteProduct($id: Int!) {
        delete_product(id: $id)
    }
`;

export const UPDATE_SPECIAL_PRICE = gql`
    mutation UpdateSpecialPrice($price: Float, $product_id: Int, $price_group_id: Int) {
        update_special_price(price: $price, product_id: $product_id, price_group_id: $price_group_id)
    }
`;

export const UPDATE_CATEGORY_PRODUCTS_POSITION = gql`
    mutation UpdateCategoryProductsPosition($input: UpdateCategoryProductsPositionInput!) {
        update_category_products_position(input: $input)
    }
`;

export const UPDATE_PRODUCT_CUSTOMER_PRICE = gql`
    mutation UpdateProductCustomerPrice($input: ProductCustomerPriceInput!) {
        update_product_customer_price(product_customer_price: $input) {
            ...ProductToCustomerPriceFields
        }
    }
    ${PRODUCT_CUSTOMER_PRICE_FIELDS},
`;

export const DELETE_PRODUCT_CUSTOMER_PRICE = gql`
    mutation DeleteProductCustomerPrice($id: Int!) {
        delete_product_customer_price(id: $id)
    }
`;

export const UPDATE_PRODUCT_PRICE_GROUP = gql`
    mutation UpdateProductPriceGroup($input: ProductPriceGroupInput!) {
        update_product_price_group(product_price_group: $input) {
            ...ProductToPriceGroupFields
        }
    }
    ${PRODUCT_PRICE_GROUP_FIELDS},
`;

export const DELETE_PRODUCT_PRICE_GROUP = gql`
    mutation DeleteProductPriceGroup($id: Int!) {
        delete_product_price_group(id: $id)
    }
`;

export default { CREATE_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT, UPDATE_SPECIAL_PRICE, UPDATE_PRODUCT_PRICE, UPDATE_CATEGORY_PRODUCTS_POSITION, UPDATE_PRODUCT_CUSTOMER_PRICE, DELETE_PRODUCT_CUSTOMER_PRICE, UPDATE_PRODUCT_PRICE_GROUP, DELETE_PRODUCT_PRICE_GROUP };
