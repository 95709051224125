
import { defineComponent, ref, toRefs } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Input, Select } from '@/components/input-elements';
import { GET_PRICE_GROUP } from '@/modules/customer/price-groups/graphql/Queries';
import { UPDATE_PRODUCT_PRICE_GROUP } from '@/modules/catalog/products/graphql/Mutations';
import { DELETE_PRODUCT_PRICE_GROUP } from '@/modules/catalog/products/graphql/Mutations';
import { GET_PRODUCT_PRICE_GROUPS } from '@/modules/catalog/products/graphql/Queries';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Table from '../../../../../components/Table/Table.vue';

export default defineComponent({
    props: {
        productId: Number
    },
    components: {
        Input,
        Select,
        Table
    },
    setup(props, context) {
        const i18n = useI18n();
        const store = useStore();
        const { productId } = toRefs(props);
        const priceGroups = ref([]) as Record<any, any>;
        const priceGroup = ref(null);
        const specialPrice = ref(null);
        const groupsList: any = ref([]);
        const priceGroupId = ref();
        const priceGroupBtn = ref(false);
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const columnName = ref(null);
        const columnSort = ref(null);
        const pagination = ref({}) as Record<any, any>;
        const page = ref(0);
        const loader = ref(false);
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.PRICE',
                key: 'special_price_original'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const getPriceGroups = () => {
            Apollo.watchQuery({
                query: GET_PRICE_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all'
            }).subscribe(({ data }) => {
                priceGroups.value = [];
                data.price_groups.data.forEach(element => {
                    priceGroups.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
            });
        };

        const getProductPriceGroups = async (page = 0, reload = false, colName = null, type = null) => {
            // if (colName != null) columnName.value = colName;
            // if (type != null) columnSort.value = type;
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            await Apollo.watchQuery({
                query: GET_PRODUCT_PRICE_GROUPS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    product_id: productId?.value,
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getProductPriceGroups;
                groupsList.value = data.product_price_groups.data;
                pagination.value = data.product_price_groups;
                loader.value = false;
            });
        };

        const addPriceGroup = async () => {
            if (!specialPrice.value || !priceGroup.value) {
                Notify.error(i18n.t('message.FILL_ALL_MISSING_FIELDS'));
            } else {
                loader.value = true;
                const product_price_group_id = null;
                if (productId) {
                    const formData = {
                        id: priceGroupId.value != undefined ? parseInt(groupsList.value[priceGroupId.value].id) : null,
                        product_id: productId.value,
                        price_group_id: priceGroup.value,
                        special_price: specialPrice.value + ''
                    };

                    await Apollo.mutate({
                        mutation: UPDATE_PRODUCT_PRICE_GROUP,
                        variables: {
                            input: formData
                        },
                        update: (store, { data: { update_product_price_group } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_PRODUCT_PRICE_GROUPS,
                                variables: {
                                    page: page.value,
                                    limit: 10,
                                    product_id: productId?.value
                                }
                            }) as Record<any, any>;

                            if (priceGroupId.value != undefined) {
                                store.writeQuery({
                                    query: GET_PRODUCT_PRICE_GROUPS,
                                    variables: {
                                        page: page.value,
                                        limit: 10,
                                        product_id: productId?.value
                                    },
                                    data: {
                                        product_price_groups: {
                                            ...pervious_record.product_price_groups
                                        },
                                        variables: {
                                            id: update_product_price_group.id
                                        }
                                    }
                                });
                            } else {
                                store.writeQuery({
                                    query: GET_PRODUCT_PRICE_GROUPS,
                                    variables: {
                                        page: 0,
                                        limit: 10,
                                        product_id: productId?.value
                                    },
                                    data: {
                                        product_price_groups: {
                                            ...pervious_record.product_price_groups,
                                            data: [update_product_price_group, ...pervious_record.product_price_groups.data]
                                        }
                                    }
                                });
                            }

                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                            loader.value = false;
                        }
                    });
                } else {
                    const groupName = priceGroups.value.find(ele => ele.value == priceGroup.value);
                    if (priceGroupId.value != undefined) {
                        groupsList.value[priceGroupId.value].id = product_price_group_id;
                        groupsList.value[priceGroupId.value].special_price_original = specialPrice.value;
                        groupsList.value[priceGroupId.value].price_group_id = priceGroup.value;
                        groupsList.value[priceGroupId.value].price_group.id = priceGroup.value;
                        groupsList.value[priceGroupId.value].price_group.name = groupName.label;
                    } else {
                        groupsList.value.push({
                            id: product_price_group_id,
                            price_group: {
                                id: priceGroup.value,
                                name: groupName.label
                            },
                            price_group_id: priceGroup.value,
                            special_price_original: specialPrice.value
                        });
                    }
                    context.emit('priceGroupsData', groupsList.value);
                    loader.value = false;
                }

                specialPrice.value = null;
                priceGroup.value = null;
                priceGroupId.value = undefined;
            }
        };

        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        const handleEdit = (index: number) => {
            specialPrice.value = groupsList.value[index].special_price_original;
            priceGroup.value = groupsList.value[index].price_group.id;
            priceGroupId.value = index;
        };

        const changePriceGroup = (id: number) => {
            const group = groupsList.value.find(ele => ele.price_group_id == id);
            if (group != undefined) {
                if (priceGroupId.value != undefined) {
                    if (groupsList.value[priceGroupId.value].price_group_id == id) {
                        priceGroupBtn.value = false;
                        return;
                    }
                }
                priceGroupBtn.value = true;
                Notify.error(i18n.t('message.PRICE_GROUP_ALREADY_EXISTS'));
            } else {
                priceGroupBtn.value = false;
            }
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    if (productId) {
                        loader.value = true;
                        await Apollo.mutate({
                            mutation: DELETE_PRODUCT_PRICE_GROUP,
                            variables: { id: parseInt(groupsList.value[id].id) },
                            update: (store, { data: { delete_product_price_group } }) => {
                                loader.value = false;
                                const pervious_record = store.readQuery({
                                    query: GET_PRODUCT_PRICE_GROUPS,
                                    variables: {
                                        page: page.value,
                                        limit: 10,
                                        product_id: productId?.value
                                    }
                                }) as Record<any, any>;

                                const data = pervious_record.product_price_groups.data.filter((t: any) => t.id !== delete_product_price_group);

                                store.writeQuery({
                                    query: GET_PRODUCT_PRICE_GROUPS,
                                    variables: {
                                        page: 0,
                                        limit: 10,
                                        product_id: productId?.value
                                    },
                                    data: {
                                        product_price_groups: {
                                            ...pervious_record.product_price_groups,
                                            data: [...data]
                                        }
                                    }
                                });
                            }
                        });
                        Notify.success('message.RECORD_DELETED_SUCCESSFULLY');
                    } else {
                        groupsList.value.splice(id, 1);
                    }
                    context.emit('priceGroupsData', groupsList.value);
                })
                .catch(e => {
                    console.log(e);
                    loader.value = false;
                });
        };

        return {
            priceGroups,
            priceGroup,
            specialPrice,
            priceGroupId,
            priceGroupBtn,
            groupsList,
            currentPage,
            columns,
            loader,
            handleEdit,
            handleDelete,
            handleCurrentChange,
            changePriceGroup,
            addPriceGroup,
            getPriceGroups,
            getProductPriceGroups,
            pagination
        };
    }
});
