import { gql } from 'graphql-tag';

export const PRODUCT_FIELDS = gql`
    fragment ProductFields on Product {
        id
        uuid
        status
        path
        economic_number
        available_stock
        family
        description {
            name
        }
        product_to_prices_admin {
            price
            site {
                name
            }
        }
        product_to_prices {
            id
            price
            tax
            site {
                name
            }
        }

        product_to_price_groups_admin {
            product_id
            price_group_id
            special_price
            special_price_original
        }
        media {
            id
            file_path
        }
    }
`;

export const FAVORITE_PRODUCT_FIELDS = gql`
    fragment FavoriteProductFields on FavoriteProduct {
        id
        product {
            id
            description {
                name
            }
        }
        customer {
            user_id
            user {
                name
            }
        }
    }
`;

export const FAVORITE_PRODUCT_PAGINATION = gql`
    fragment FavoriteProductPagination on FavoriteProductPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;

export const PAGINATION = gql`
    fragment Pagination on ProductPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;



export const CATEGORY_PRODUCTS_FIELDS = gql`
    fragment CategoryProductsFields on ProductToCategory {
        id
        product_id
        position
        category_id
        product{
            id
            status
            available_stock
            economic_number
            description {
                name
            }
        }
        
    }
`;

export const PRODUCT_TO_CATEGORY_PAGINATION = gql`
    fragment Pagination on ProductToCategoryPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;

export const PRODUCT_PRICE_GROUP_FIELDS = gql`
    fragment ProductToPriceGroupFields on ProductToPriceGroup {
        id
        price_group_id
        price_group {
            id
            name
        }
        special_price
        special_price_original
    }
`;

export const PRODUCT_PRICE_GROUP_PAGINATION = gql`
    fragment ProductToPriceGroupPagination on ProductToPriceGroupPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;

export const PRODUCT_CUSTOMER_PRICE_FIELDS = gql`
    fragment ProductToCustomerPriceFields on ProductToCustomerPrice {
        id
        customer_id
        customer {
            id
            user_id
            user {
                name
            }
            parent {
                id
                name
                customer {
                  id
                  economic_number
                }
              }
        }
        special_price
        special_price_original
    }
`;

export const PRODUCT_CUSTOMER_PRICE_PAGINATION = gql`
    fragment ProductToCustomerPricePagination on ProductToCustomerPricePagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;

export default { PRODUCT_FIELDS, PAGINATION, FAVORITE_PRODUCT_FIELDS, FAVORITE_PRODUCT_PAGINATION, CATEGORY_PRODUCTS_FIELDS, PRODUCT_TO_CATEGORY_PAGINATION, PRODUCT_PRICE_GROUP_FIELDS, PRODUCT_PRICE_GROUP_PAGINATION, PRODUCT_CUSTOMER_PRICE_FIELDS, PRODUCT_CUSTOMER_PRICE_PAGINATION };
