
import { defineComponent, ref, watchEffect, toRefs } from "vue";
import { Apollo, Notify } from "@/core/services";
import { GET_DIMENSIONS } from "@/modules/catalog/dimensions/graphql/Queries";
import { Select } from "@/components/input-elements";
import { useI18n } from "vue-i18n";
import Table from "../../../../../components/Table/Table.vue";

export default defineComponent({
	props: {
		dimensionListing: Object,
	},
	components: {
		Select,
		Table,
	},
	setup(props, context) {
		const i18n = useI18n();
		const { dimensionListing } = toRefs(props);
		const dimensions = ref([]) as Record<any, any>;
		const dimension = ref();
		const value = ref();
		const dimensionsList: any = ref([]);
		const dimensionId = ref();
		const dimensionButton = ref(false);
		const unit = ref("unit");
		const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.MEASUREMENT",
				key: "unit",
			},
			{
				label: "message.VALUE",
				key: "value",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		watchEffect(() => {
			dimensionListing?.value != undefined ? (dimensionsList.value = dimensionListing?.value) : null;
		});

		const getDimension = () => {
			Apollo.watchQuery({
				query: GET_DIMENSIONS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
			}).subscribe(({ data }) => {
				dimensions.value = [];
				data.dimensions.data.forEach((element) => {
					dimensions.value.push({
						label: element.name,
						value: element.id,
						unit: element.measurement,
					});
				});
			});
		};

		const addDimension = () => {
			if (!dimension.value || !value.value) {
				Notify.error(i18n.t("message.FILL_ALL_MISSING_FIELDS"));
			} else {
				const findDimension = dimensions?.value?.find((ele) => ele.value == dimension.value);
				if (dimensionId.value != undefined) {
					dimensionsList.value[dimensionId.value].name = findDimension.label;
					dimensionsList.value[dimensionId.value].dimension_id = dimension.value;
					dimensionsList.value[dimensionId.value].value = value.value;
				} else {
					dimensionsList.value.push({
						name: findDimension.label,
						dimension_id: dimension.value,
						value: value.value,
						unit: findDimension.unit,
					});
				}

				dimension.value = null;
				value.value = null;
				dimensionId.value = undefined;
				unit.value = "unit";
				context.emit("dimensionData", dimensionsList.value);
			}
		};

		const handleEdit = (index: number) => {
			dimension.value = dimensionsList.value[index].dimension_id;
			value.value = dimensionsList.value[index].value;
			dimensionId.value = index;
			unit.value = dimensionsList.value[index].unit;
		};

		const changeDimension = (id: number) => {
			if (id != undefined) {
				const findDimension = dimensionsList.value?.find((ele) => ele.dimension_id == id);
				if (findDimension != undefined) {
					if (dimensionId.value != undefined) {
						if (dimensionsList.value[dimensionId.value].dimension_id == id) {
							dimensionButton.value = false;
							return;
						}
					}
					dimensionButton.value = true;
					Notify.error(i18n.t("message.DIMENSION_ALREADY_EXISTS"));
				} else {
					dimensionButton.value = false;
				}

				const info = dimensions?.value?.find((ele) => ele.value == id);
				unit.value = info.unit;
			} else {
				dimensionButton.value = false;
				unit.value = "unit";
			}
		};

		const handleDelete = (index: number) => {
			dimensionsList?.value?.splice(index, 1);
			context.emit("dimensionData", dimensionsList.value);
		};

		return {
			dimensions,
			dimension,
			value,
			dimensionsList,
			unit,
			dimensionButton,
			dimensionId,
			columns,
			handleEdit,
			handleDelete,
			addDimension,
			changeDimension,
			getDimension
		};
	},
});
