
import { defineComponent, ref, watchEffect, toRefs, onMounted, watch } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { GET_TAX_RULE } from '@/modules/system/tax-rules/graphql/Queries';
import { Input, Select } from '@/components/input-elements';
import { useI18n } from 'vue-i18n';
import Table from '../../../../../components/Table/Table.vue';
import PriceChanging from '../partials/PriceChanging.vue';

export default defineComponent({
    props: {
        locale: { type: String, default: 'en' },
        sites: Object,
        pricesList: Object
    },
    components: {
        Input,
        Select,
        Table,
        PriceChanging
    },
    setup(props, context) {
        const { locale, sites, pricesList } = toRefs(props);
        const i18n = useI18n();
        const price = ref(null) as Record<any, any>;
        const tax = ref(0);
        const taxRule = ref(0);
        const deleteValue = ref(0);
        const taxRules = ref([]) as Record<any, any>;
        const taxRulesList = ref([]);
        const priceId = ref();
        const pricebtn = ref(false);
        const prices: any = ref([]);
        const siteId = ref(null);
        const taxOnPrice = ref(0);
        const activeIndex = ref(-1);
        const count = ref(props?.sites?.length);
        const columns = ref([
            {
                label: 'message.SITE_NAME',
                key: 'site_name'
            },
            {
                label: 'message.PRICE',
                key: 'price'
            },
            {
                label: 'message.PRICE_WITH_TAX',
                key: 'tax'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const getTaxRules = (id: number) => {
            Apollo.watchQuery({
                query: GET_TAX_RULE,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    id: id
                }
            }).subscribe(({ data }) => {
                taxRulesList.value = [];
                taxRulesList.value = data.tax_rules?.data;
            });
        };

        watchEffect(() => {
            // Extract the codes from matchingIds
            const siteCodes = sites?.value?.map(item => item?.value) || [];

            // Filter pricesList based on the extracted codes
            if (pricesList?.value) {
                prices.value = pricesList.value.filter(item => siteCodes.includes(item?.site_id));
            } else {
                prices.value = null;
            }

            // Reset values if the siteCodes length does not match count
            if (siteCodes.length !== count.value) {
                siteId.value = null;
                taxRule.value = 0;
                price.value = null;
                tax.value = 0;
            }

            // Update the count value
            count.value = siteCodes.length;

            // Initialize taxRules with default value
            taxRules.value = [
                {
                    label: `${i18n.t('message.NO')} ${i18n.t('message.TAX')}`,
                    value: 0
                }
            ];

            // Populate taxRules based on taxRulesList
            taxRulesList.value.forEach((element: Record<any, any>) => {
                const localeValue = locale.value;
                const nameObject = JSON.parse(element.name);
                const defaultLocale = nameObject[localeValue] ? localeValue : 'en';

                taxRules.value.push({
                    label: nameObject[defaultLocale].name,
                    value: element.id,
                    tax: element.tax
                });
            });
        });

        const addPrice = () => {
            if (!price.value || !siteId.value) {
                Notify.error(i18n.t('message.FILL_ALL_MISSING_FIELDS'));
            } else {
                if (!prices.value) {
                    prices.value = [];
                }
                const site = sites?.value?.find(ele => ele.value == siteId.value);
                if (priceId.value != undefined) {
                    prices.value[priceId.value].tax = tax.value;
                    prices.value[priceId.value].site_name = site.label;
                    prices.value[priceId.value].site_id = site.value;
                    prices.value[priceId.value].price = price.value;
                    prices.value[priceId.value].tax_rule_id = taxRule.value;
                    prices.value[priceId.value].tax_on_price = taxOnPrice.value;
                } else {
                    prices.value.push({
                        price: price.value,
                        site_name: site.label,
                        site_id: site.value,
                        tax: tax.value,
                        tax_rule_id: taxRule.value,
                        tax_on_price: taxOnPrice.value
                    });
                }

                price.value = null;
                siteId.value = null;
                tax.value = 0;
                taxRule.value = 0;
                priceId.value = undefined;
                taxOnPrice.value = 0;
                context.emit('priceData', prices.value);
            }
        };
        const changeSite = (id: number) => {
            getTaxRules(id);
            const price = prices.value?.find(ele => ele.site_id == id);
            if (price != undefined) {
                if (priceId.value != undefined) {
                    if (prices.value[priceId.value].site_id == id) {
                        pricebtn.value = false;
                        return;
                    }
                }
                pricebtn.value = true;
                Notify.error(i18n.t('message.COMBINATION_TAX_PRICE_EXISTS'));
            } else {
                pricebtn.value = false;
            }
        };

        const handleEdit = (index: number) => {
            tax.value = prices.value[index].tax;
            taxRule.value = prices.value[index].tax_rule_id;
            siteId.value = prices.value[index].site_id;
            priceId.value = index;
            price.value = Number(prices.value[index].price);
            changeSite(prices.value[index]?.site_id);
        };

        const handleDelete = (id: number) => {
            prices.value.splice(id, 1);
            context.emit('priceData', prices.value);
            deleteValue.value = 1;
        };

        const changeTax = () => {
            if (taxRule.value != 0) {
                const getTax = taxRulesList.value.find((ele: Record<any, any>) => ele.id == taxRule.value) as any;
                if (getTax?.tax.rate_type == 'Amount') {
                    taxOnPrice.value = Number(getTax?.tax.rate);
                    tax.value = price.value == null ? 0 : Number(price.value) + Number(getTax?.tax.rate);
                } else if (getTax?.tax.rate_type == 'Percentage') {
                    taxOnPrice.value = (Number(price.value) * Number(getTax?.tax.rate)) / 100;
                    tax.value = Number(price.value) + Number(taxOnPrice.value.toFixed(2));
                }
            } else {
                tax.value = Number(price.value);
            }
        };

        const updateValue = () => {
            if (!price.value) {
                tax.value = 0;
                return;
            }
            changeTax();
        };

        const dropdownHandler = (index, id, del) => {
            deleteValue.value = 0;
            if (del == 0) {
                activeIndex.value = index === id ? null : id;
            } else {
                activeIndex.value = -1;
            }
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        onMounted(() => {
            document.addEventListener('click', handleClickOutside);
        });

        return {
            taxRules,
            price,
            tax,
            taxRule,
            priceId,
            pricebtn,
            siteId,
            prices,
            columns,
            activeIndex,
            deleteValue,
            handleEdit,
            handleDelete,
            changeTax,
            updateValue,
            changeSite,
            addPrice,
            dropdownHandler,
            handleClickOutside
        };
    }
});
