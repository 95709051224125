
import { defineComponent, ref, toRefs } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Input, Select } from '@/components/input-elements';
import { GET_CUSTOMER_DROPDOWN } from '@/modules/customer/customers/graphql/Queries';
import { UPDATE_PRODUCT_CUSTOMER_PRICE } from '@/modules/catalog/products/graphql/Mutations';
import { DELETE_PRODUCT_CUSTOMER_PRICE } from '@/modules/catalog/products/graphql/Mutations';
import { GET_PRODUCT_CUSTOMER_PRICES } from '@/modules/catalog/products/graphql/Queries';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Table from '../../../../../components/Table/Table.vue';

export default defineComponent({
    props: {
        productId: Number
    },
    components: {
        Input,
        Select,
        Table
    },
    setup(props, context) {
        const i18n = useI18n();
        const store = useStore();
        const { productId } = toRefs(props);
        const customers = ref([]) as Record<any, any>;
        const customer = ref(null);
        const specialPrice = ref(null);
        const pricesList: any = ref([]);
        const customerPriceId = ref();
        const customerPriceBtn = ref(false);
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const columnName = ref(null);
        const columnSort = ref(null);
        const pagination = ref({}) as Record<any, any>;
        const page = ref(0);
        const loader = ref(false);
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.PRICE',
                key: 'special_price_original'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);
        const getCustomers = () => {
            Apollo.watchQuery({
                query: GET_CUSTOMER_DROPDOWN,
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                errorPolicy: 'all'
            }).subscribe(({ data }) => {
                customers.value = [];
                data.customers_dropdown.data.forEach(element => {
                    customers.value.push({
                        label: element?.user?.name,
                        value: element.id
                    });
                });
            });
        };

        const getProductCustomerPrices = async (page = 0, reload = false, colName = null, type = null) => {
            // if (colName != null) columnName.value = colName;
            // if (type != null) columnSort.value = type;
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            await Apollo.watchQuery({
                query: GET_PRODUCT_CUSTOMER_PRICES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    product_id: productId?.value,
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getProductCustomerPrices;
                pricesList.value = data?.product_customer_prices?.data;
                // pricesList.value = data?.product_customer_prices?.data.filter(item => item?.customer?.parent === null);
                // Create a new object with updated total property
                const updatedProductCustomerPrices = {
                    ...data.product_customer_prices,
                    total: pricesList.value.length
                };

                // Assign the updated object to pagination
                pagination.value = updatedProductCustomerPrices;
                loader.value = false;
            });
        };

        const addCustomerPrice = async () => {
            if (!specialPrice.value || !customer.value) {
                Notify.error(i18n.t('message.FILL_ALL_MISSING_FIELDS'));
            } else {
                loader.value = true;
                const product_customer_price_id = null;
                if (productId) {
                    const formData = {
                        id: customerPriceId.value != undefined ? parseInt(pricesList.value[customerPriceId.value].id) : null,
                        product_id: productId.value,
                        customer_id: customer.value,
                        special_price: specialPrice.value + ''
                    };

                    await Apollo.mutate({
                        mutation: UPDATE_PRODUCT_CUSTOMER_PRICE,
                        variables: {
                            input: formData
                        },
                        update: (store, { data: { update_product_customer_price } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_PRODUCT_CUSTOMER_PRICES,
                                variables: {
                                    page: page.value,
                                    limit: 10,
                                    product_id: productId?.value
                                }
                            }) as Record<any, any>;

                            if (customerPriceId.value != undefined) {
                                store.writeQuery({
                                    query: GET_PRODUCT_CUSTOMER_PRICES,
                                    variables: {
                                        page: page.value,
                                        limit: 10,
                                        product_id: productId?.value
                                    },
                                    data: {
                                        product_customer_prices: {
                                            ...pervious_record.product_customer_prices
                                        },
                                        variables: {
                                            id: update_product_customer_price.id
                                        }
                                    }
                                });
                            } else {
                                store.writeQuery({
                                    query: GET_PRODUCT_CUSTOMER_PRICES,
                                    variables: {
                                        page: 0,
                                        limit: 10,
                                        product_id: productId?.value
                                    },
                                    data: {
                                        product_customer_prices: {
                                            ...pervious_record.product_customer_prices,
                                            data: [update_product_customer_price, ...pervious_record.product_customer_prices.data]
                                        }
                                    }
                                });
                            }

                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                            loader.value = false;
                        }
                    });
                } else {
                    const customerName = customers.value.find(ele => ele.value == customer.value);
                    if (customerPriceId.value != undefined) {
                        pricesList.value[customerPriceId.value].id = product_customer_price_id;
                        pricesList.value[customerPriceId.value].special_price_original = specialPrice.value;
                        pricesList.value[customerPriceId.value].customer_id = customer.value;
                        pricesList.value[customerPriceId.value].customer.id = customer.value;
                        pricesList.value[customerPriceId.value].customer.user.name = customerName.label;
                    } else {
                        pricesList.value.push({
                            id: product_customer_price_id,
                            customer: {
                                id: customer.value,
                                user: {
                                    name: customerName.label
                                }
                            },
                            customer_id: customer.value,
                            special_price_original: specialPrice.value
                        });
                    }
                    context.emit('customerPricesData', pricesList.value);
                    loader.value = false;
                }

                specialPrice.value = null;
                customer.value = null;
                customerPriceId.value = undefined;
            }
        };

        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        const handleEdit = (index: number) => {
            specialPrice.value = pricesList.value[index].special_price_original;
            customer.value = pricesList.value[index].customer.id;
            customerPriceId.value = index;
        };

        const changeCustomer = (id: number) => {
            const group = pricesList.value.find(ele => ele.customer_id == id);
            if (group != undefined) {
                if (customerPriceId.value != undefined) {
                    if (pricesList.value[customerPriceId.value].customer_id == id) {
                        customerPriceBtn.value = false;
                        return;
                    }
                }
                customerPriceBtn.value = true;
                Notify.error(i18n.t('message.CUSTOMER_ALREADY_EXISTS'));
            } else {
                customerPriceBtn.value = false;
            }
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    if (productId) {
                        loader.value = true;
                        await Apollo.mutate({
                            mutation: DELETE_PRODUCT_CUSTOMER_PRICE,
                            variables: { id: parseInt(pricesList.value[id].id) },
                            update: (store, { data: { delete_product_customer_price } }) => {
                                loader.value = false;
                                const pervious_record = store.readQuery({
                                    query: GET_PRODUCT_CUSTOMER_PRICES,
                                    variables: {
                                        page: page.value,
                                        limit: 10,
                                        product_id: productId?.value
                                    }
                                }) as Record<any, any>;

                                const data = pervious_record.product_customer_prices.data.filter((t: any) => t.id !== delete_product_customer_price);

                                store.writeQuery({
                                    query: GET_PRODUCT_CUSTOMER_PRICES,
                                    variables: {
                                        page: 0,
                                        limit: 10,
                                        product_id: productId?.value
                                    },
                                    data: {
                                        product_customer_prices: {
                                            ...pervious_record.product_customer_prices,
                                            data: [...data]
                                        }
                                    }
                                });
                            }
                        });
                        Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                    }
                    context.emit('customerPricesData', pricesList.value);
                })
                .catch(e => {
                    console.log(e);
                    loader.value = false;
                });
        };

        return {
            customers,
            customer,
            specialPrice,
            customerPriceId,
            customerPriceBtn,
            pricesList,
            currentPage,
            columns,
            loader,
            handleEdit,
            handleDelete,
            handleCurrentChange,
            changeCustomer,
            addCustomerPrice,
            getCustomers,
            getProductCustomerPrices,
            pagination
        };
    }
});
